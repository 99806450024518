// Use prebuilt version of RNVI in dist folder
import Icon from 'react-native-vector-icons/dist/AntDesign';
// Import font file
import * as fontAwesome from 'react-native-vector-icons/Fonts/AntDesign.ttf';

let iconFonts:any = [];
iconFonts['AntDesign'] = fontAwesome;

for(const key in iconFonts) {
	const iconFontStyles = `@font-face {
	  src: url(${iconFonts[key]});
	  font-family: ${key};
	}`;

	// Create stylesheet
	const style = document.createElement('style');
	style.type = 'text/css';
	// if (style.styleSheet) {
	//   style.styleSheet.cssText = iconFontStyles;
	// } else {
	  style.appendChild(document.createTextNode(iconFontStyles));
	// }

	// Inject stylesheet
	document.head.appendChild(style);
}
