"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.colors = {
  white: '#FFF',
  black: '#212121',
  red: '#992F2F',
  greyDark: '#888',
  grey: '#B3B3B3',
  greyLight: '#E6E6E6'
};
exports.default = {
  container: {},
  content: {
    padding: 15,
    paddingTop: 65
  },
  textShadow: {
    textShadowColor: 'rgba(0, 0, 0, 0.25)',
    textShadowOffset: {
      width: 0,
      height: 1
    },
    textShadowRadius: 4
  },
  button: {
    paddingTop: 10,
    paddingBottom: 10,
    backgroundColor: exports.colors.greyDark,
    borderRadius: 4
  },
  button__label: {
    color: exports.colors.white,
    fontSize: 18,
    textAlign: 'center',
    fontFamily: 'Fredoka One'
  },
  button__icon: {
    marginLeft: 10
  },
  hr: {
    borderBottomColor: exports.colors.greyLight,
    borderBottomWidth: 1,
    marginTop: 15,
    marginBottom: 15
  }
};