import React from 'react';
import { AppRegistry, Text, View } from 'react-native';
import App from './App';
import * as serviceWorker from './serviceWorker';
import './icons';

// register the app
AppRegistry.registerComponent('App', () => App);
// export const Index = () => {
//   return (
//     <View>
//       <Text>Hello</Text>
//     </View>
//   );
// };

// AppRegistry.registerComponent('App', () => Index);

AppRegistry.runApplication('App', {
  initialProps: {},
  rootTag: document.getElementById('root')
});

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.register();
