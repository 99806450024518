"use strict";

var __decorate = this && this.__decorate || function (decorators, target, key, desc) {
  var c = arguments.length,
      r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc,
      d;
  if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);else for (var i = decorators.length - 1; i >= 0; i--) {
    if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
  }
  return c > 3 && r && Object.defineProperty(target, key, r), r;
};

Object.defineProperty(exports, "__esModule", {
  value: true
});

var mobx_1 = require("mobx");

var mobx_persist_1 = require("mobx-persist");

var react_1 = require("react");

var react_native_1 = require("react-native");

var mobx_react_router_1 = require("mobx-react-router");

var react_native_2 = require("react-native");

var CurrentStore_1 = require("./CurrentStore");

var PreviousStore_1 = require("./PreviousStore");

var hydrate = mobx_persist_1.create({
  storage: react_native_1.AsyncStorage,
  jsonify: true
});

var RootStore = function () {
  function RootStore() {
    this.test = 'here';
    this.paneFocusValueFrom = 0;
    this.paneFocusValueTo = 0;
    this.paddingValueFrom = 0;
    this.paddingValueTo = 0;
    this.paddingAnimation = new react_native_2.Animated.Value(15);
    this.router = new mobx_react_router_1.RouterStore();
    this.current = new CurrentStore_1.CurrentStore();
    this.previous = new PreviousStore_1.PreviousStore();
    hydrate('current', this.current);
    hydrate('previous', this.previous);
  }

  __decorate([mobx_1.observable], RootStore.prototype, "test", void 0);

  __decorate([mobx_1.observable], RootStore.prototype, "paneFocusValueFrom", void 0);

  __decorate([mobx_1.observable], RootStore.prototype, "paneFocusValueTo", void 0);

  __decorate([mobx_1.observable], RootStore.prototype, "paddingValueFrom", void 0);

  __decorate([mobx_1.observable], RootStore.prototype, "paddingValueTo", void 0);

  __decorate([mobx_1.observable], RootStore.prototype, "paddingAnimation", void 0);

  return RootStore;
}();

exports.RootStore = RootStore;
exports.rootStore = new RootStore();
exports.RootStoreContext = react_1.createContext(exports.rootStore);