"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = {
  millisecondsToCounter: function millisecondsToCounter(milliseconds) {
    var seconds = Math.floor(milliseconds / 1000 % 60),
        minutes = Math.floor(milliseconds / (1000 * 60) % 60),
        hours = Math.floor(milliseconds / (1000 * 60 * 60));
    var hoursPrint = hours < 10 ? "0" + hours : hours;
    var minutesPrint = minutes < 10 ? "0" + minutes : minutes;
    var secondsPrint = seconds < 10 ? "0" + seconds : seconds;
    return hoursPrint + ":" + minutesPrint + ":" + secondsPrint;
  },
  millisecondsToDate: function millisecondsToDate(milliseconds) {
    var date = new Date(milliseconds);
    var monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'October', 'Nov', 'Dec'];
    return date.getDate() + ' ' + monthNames[date.getMonth()] + ' ' + date.getFullYear();
  }
};